import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import default styles
import StockNews from 'components/StockNews';
import StockProfile from 'components/StockProfile';
import BalanceSheet from 'components/BalanceSheet';
import IncomeStatement from 'components/IncomeStatement';
import BalanceSheetChart from 'components/BalanceSheetChart';
import IncomeStatementChart from 'components/IncomeStatementChart';
import FundamentalData from 'components/FundamentalData';


const StockViewTabs = ({ stockInfo }) => {

    return (
        <div className="container p-4 ">
            <Tabs >
                <TabList className="flex border-b border-gray-300 mb-4 gap-4">
                    <Tab className="text-sm pb-2 cursor-pointer text-black" selectedClassName="border-b-2 border-blue-500 text-blue-500">Summary</Tab>
                    <Tab className="text-sm pb-2 cursor-pointer text-black" selectedClassName="border-b-2 border-blue-500 text-blue-500">Fundamental Data</Tab>
                    <Tab className="text-sm pb-2 cursor-pointer text-black" selectedClassName="border-b-2 border-blue-500 text-blue-500">Balance Sheet</Tab>
                    <Tab className="text-sm pb-2 cursor-pointer text-black" selectedClassName="border-b-2 border-blue-500 text-blue-500">Income Statement</Tab>
                </TabList>

                <TabPanel>
                    <div className='flex flex-col w-full h-[350px] overflow-auto'>
                        {stockInfo && stockInfo.profile ? <StockProfile stockInfo={stockInfo}/> : null}
                    </div>

                    <div className="mt-8 h-[800px]">
                        {stockInfo && stockInfo.profile ? <StockNews stockInfo={stockInfo} /> : null}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className='flex flex-col w-full h-[800px] overflow-auto'>
                        {stockInfo && stockInfo.profile ? <FundamentalData stockInfo={stockInfo}/> : null}
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className="p-4 bg-white rounded-lg">
                        <BalanceSheetChart data={stockInfo.balanceSheetGraphPoints} />

                        <BalanceSheet stockInfo={stockInfo} />
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className="p-4 bg-white rounded-lg">
                        <IncomeStatementChart data={stockInfo.incomeStatementGraphPoints}/>

                        <IncomeStatement stockInfo={stockInfo} />
                    </div>
                </TabPanel>
            </Tabs>

        </div>
    );
};

export default StockViewTabs;