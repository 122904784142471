const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export const fetchClasses = async () => {
    const response = await fetch(`${API_ENDPOINT}/api/Classroom`, {
        credentials: 'include',
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch classrooms" };
    }

    const data = await response.json();
    return data;
};

export const fetchCourses = async () => {
    const response = await fetch(`${API_ENDPOINT}/api/Courses`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch courses" };
    }

    const data = await response.json();
    return data;
};

export const fetchClassroomDetails = async (classroomId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Classroom/${classroomId}`, {
        credentials: 'include',
    });


    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch classroom details" };
    }

    const data = await response.json();
    return data;
};

export async function createClassroom(classroomData) {
    const url = `${API_ENDPOINT}/api/Classroom/New`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(classroomData),
        });

        const responseText = await response.text();

        if (!response.ok) {
            throw { status: response.status, message: "Failed to create classroom" };
        }

        const responseData = JSON.parse(responseText);
        return responseData;
    } catch (error) {
        console.error('Error creating classroom:', error);
        throw error;
    }
}

export async function createShareCode(classroomId) {
    const url = `${API_ENDPOINT}/api/Classroom/${classroomId}/Tokens/New`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include'
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to create a share code" };
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error creating classroom:', error);
        throw error;
    }
}

export async function addCourseToClassroom(classroomId, courseId) {

    try {
        const response = await fetch(`${API_ENDPOINT}/api/Classroom/${classroomId}/AddCourse/${courseId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw { status: response.status, message: "Failed to add course to classroom" };
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function addTeacherToClassroom(classroomId, teacherId) {

    try {
        const response = await fetch(`${API_ENDPOINT}/api/Classroom/${classroomId}/Teachers/${teacherId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw { status: response.status, message: "Failed to add teacher to classroom" };
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function addTeacherToClassroomByEmail(classroomId, teacherEmail) {

    try {
        var urlEncodedEmail = encodeURIComponent(teacherEmail);
        const response = await fetch(`${API_ENDPOINT}/api/Classroom/${classroomId}/TeacherEmail/${urlEncodedEmail}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            return {
                success: true,
                status: response.status,
                message: 'Co-instructor added successfully'
            };
        } else {
            let errorMessage = 'Failed to add co-instructor. Please ensure the email is correct and try again.';
            try {
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/json')) {
                    const errorBody = await response.json();
                    errorMessage = errorBody.message || errorMessage;
                } else {
                    const errorText = await response.text();
                    errorMessage = errorText || errorMessage;
                }
            } catch (error) {
                console.error('Error parsing response:', error);
            }
            return {
                success: false,
                status: response.status,
                message: errorMessage
            };
        }
    } catch (error) {
        console.error('Error:', error);
        return {
            success: false,
            status: 500,
            message: error.message || 'An unexpected error occurred'
        };
    }
}

export const fetchStudentDetails = async (studentId) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/api/Students/${studentId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to fetch student details" };
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching student details:', error);
        throw error;
    }
};


export const fetchAnalyticsData = async (studentId, courseId) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/api/Students/${studentId}/Analytics/Course/${courseId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'accept': 'application/json', // Adjust the 'accept' header as needed
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to fetch analytics data" };
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching analytics data:', error);
        throw error;
    }
};

export const fetchStudentProgress = async (courseId, studentId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Students/${studentId}/Status?courseId=${courseId}`, {
        credentials: 'include',
    });


    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch student progress" };
    }

    const data = await response.json();
    return data;
};


export const fetchCourseDetails = async (courseId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Courses/${courseId}`, {
        method: 'GET',
        credentials: 'include',
    });


    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch course details" };
    }

    const data = await response.json();
    return data;
};

export const addAssignment = async (classroomId, assignmentData) => {
    const response = await fetch(`${API_ENDPOINT}/api/Classroom/${classroomId}/AddAssignment`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(assignmentData),
    });

    if (!response.ok) {
        throw new Error('Failed to add assignment');
    }

    // If there's no response body, return null or some success message
    return null;
};

export const fetchUnitDetails = async (unitId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Courses/Units/${unitId}`, {
        credentials: 'include',
        headers: {
            'accept': 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch unit details" };
    }

    const data = await response.json();
    return data;
};

export const fetchSectionDetails = async (sectionId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Courses/Units/Sections/${sectionId}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch section details" };
    }

    const data = await response.json();
    return data;
};

export async function deleteStudent(studentId, ignoreExistingActivity) {
    const url = `${API_ENDPOINT}/DeleteStudent/${studentId}?ignoreExistingActivity=${ignoreExistingActivity}`;
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            },
        });

        if (!response.ok) {
            let errorMessage = 'Failed to delete student';
            let errorData = {};

            // Try to parse the error response
            try {
                const responseText = await response.text();
                if (responseText) {
                    errorData = JSON.parse(responseText);
                    errorMessage = errorData.message || errorMessage;
                }
            } catch (parseError) {
                console.error('Error parsing the error response:', parseError);
            }

            throw { status: response.status, message: errorMessage };
        }

    } catch (error) {
        console.error('Error deleting student:', error);
        throw error;
    }
}




